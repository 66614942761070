import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import 'bootstrap-icons/font/bootstrap-icons.css';

class CardInfo extends Component {
    render() {
        const { t, member } = this.props;
        return (
            <>
                <div className={"card-img div-img " + member.header.image}>

                </div>
                <a
                    href={`https://instagram.com/${member.header.instaUser}`}
                    target="_"
                    rel="noopener noreferrer"
                    className="btn button-zgz-barbell insta-header"
                >
                    <i className="bi bi-instagram"></i>
                </a>
                <section className="section-info">
                    <h1 className="name">{member.header.name}</h1>
                    <h3 className="stack">{t(member.header.teamRole)}</h3>
                </section>
            </>
        );
    }
}

export default withTranslation()(CardInfo);