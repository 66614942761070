import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { withTranslation } from 'react-i18next';
import teamData from './data/team.json';
import Card from './Card';

class Team extends Component {
    render() {
        const { t } = this.props;

        return (
            <div className="section-separation team" id="team" >
                <h1>{t('team.title')}</h1>
                <h3>{t('team.subtitle')}</h3>
                <Row xs={1} sm={1} md={2} lg={3} xl={4} xxl={5} className="g-4 justify-content-md-center">
                    {teamData.map((member, index) => (
                        <Col key={index}>
                            <Card member={member} />
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }
}

export default withTranslation()(Team);