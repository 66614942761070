import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { withTranslation } from 'react-i18next';
import CookiePolicyBanner from './CookiePolicyBanner';
import Team from './Team';
import Gym from './Gym';
import Club from './Club';
import Contact from './Contact';
import ListInfo from './ListInfo';
// import Contact from './Contact';
import img1 from '../assets/img/index/zgzb1.jpg';
import img2 from '../assets/img/index/zgzb2.jpg';
import img3 from '../assets/img/index/zgzb3.jpg';
import img4 from '../assets/img/index/zgzb4.jpg';
import rates from './data/rates.json';
import '../styles/Home.css';


class Home extends Component {
  static displayName = Home.name;

  render() {
    const { t } = this.props;

    return (
      <>
        <CookiePolicyBanner />
        <div className="home-carousel" id="carousel">
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={img1} alt={t('carousel.slide1.title')} />
              <Carousel.Caption>
                <h1>{t('carousel.slide1.title')}</h1>
                <h3>{t('carousel.slide1.subtitle')}</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img2} alt="" />
              <Carousel.Caption>
                <h1>{t('carousel.slide2.title')}</h1>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img3} alt="" />
              <Carousel.Caption>
                <h1>{t('carousel.slide3.title')}</h1>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={img4} alt="" />
              <Carousel.Caption>
                <h1>{t('carousel.slide4.title')}</h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
        <Team />
        <Gym />
        <Club />
        <ListInfo itemListInfo={rates} />
        <Contact />
      </>
    );
  }
}

export default withTranslation()(Home);