import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class ItemInfo extends Component {
    render() {
        const { t, item, minHeight } = this.props;

        return (
            <>
                <div className={'div-img-item ' + (item.img ? 'with-img ' + item.img : '')} style={{ padding: '10px', minHeight: minHeight }}>
                    {item.title && <h4>{t(item.title)}</h4>}
                    {item.description && <p>{t(item.description)}*</p>}
                    {item.subdescription && <p>{t(item.subdescription)}</p>}
                </div>
            </>
        );
    }
}

export default withTranslation()(ItemInfo);