import React from 'react';
import Popup from 'reactjs-popup';
import { withTranslation } from 'react-i18next';
import ListInfo from './ListInfo';

class DetailButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    closeModal = () => {
        this.setState({ open: false });
    }

    render() {
        const { t, buttonText, detailObject, isPortrait } = this.props;

        return (
            <>
                <button id='detailButton' className='button-zgz-barbell' onClick={() => this.setState({ open: true })}>
                    <i >{t(buttonText)}</i>
                </button>

                <Popup open={this.state.open} closeOnDocumentClick onClose={this.closeModal}>
                    <div className='gym-detail-popup'>
                        <div className='detail-button'>
                            <button className='button-zgz-barbell close-button' onClick={this.closeModal}>
                                <i class="bi bi-x-lg"></i>
                            </button>
                        </div>
                        <div className='detail-text'>
                            <div className='detail-title'>
                                <ListInfo itemListInfo={detailObject} minHeight='20vh' isPortrait={isPortrait} />
                            </div>
                        </div>
                    </div>
                </Popup>
            </>
        );
    }
}

export default withTranslation()(DetailButton);