import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import clubData from './data/club.json';
import '../styles/Gym.css';

class Club extends Component {
    render() {
        const { t } = this.props;

        let element = ` <a href="${t(clubData.link)}" target="_blank">${t(clubData.replaceKey)}</a> `;

        return (
            <div className="section-separation" id={clubData.id}>
                <div className={`${clubData.id}-title`}>
                    <h1>{t(clubData.title)}</h1>
                </div>
                <div className={`section-description ${clubData.id} img-rigth`}>
                    <div className={"img div-img " + clubData.img}>
                    </div>
                    <div className="description-overlay">
                        <h1 className='club-title-description'>{t(clubData.subtitle)}</h1>
                        <p dangerouslySetInnerHTML={{ __html: t(clubData.text).replace(t('club.aep'), element) }}></p>
                    </div>
                </div>
            </div>
        );
    }
};

export default withTranslation()(Club);