import React from 'react';
import { useTranslation } from 'react-i18next';
import brandsData from './data/brands.json';
import '../styles/Gym.css';

const Brands = () => {
    const { t } = useTranslation();

    return (
        <div className="section-separation" id={brandsData.id}>
            <div className={`${brandsData.id}-title`}>
                <h1>{t(brandsData.title)}</h1>
            </div>
            <div className={`section-description brand-description ${brandsData.id}`}>
                {brandsData.data.map((brand, index) => (
                    <div key={index}>
                        <a href={brand.link} target='_blank' rel="noreferrer">{t(brand.title)}</a><span> - {t(brand.text)}</span>
                    </div>
                ))}
            </div>
        </div>
    );

};

export default Brands;