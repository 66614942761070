import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import clubData from './data/club.json';

class CardSection extends Component {
    render() {
        const { t } = this.props;
        let element = ` <a href="${t(clubData.link)}" target="_blank">${t(clubData.replaceKey)}</a> `;
        return (
            <>
                <section className={`section_${t(this.props.sectionName)}`}>
                    <p className={"card-section-text"} dangerouslySetInnerHTML={{ __html: t(this.props.sectionText).replace(t('club.aep'), element) }}></p>
                </section>
            </>
        );
    }
}

export default withTranslation()(CardSection);