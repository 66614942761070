import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import Brands from './Brands';
import DetailButton from './DetailButton';
import gymData from './data/gym.json';
import '../styles/Gym.css';

class Gym extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPortrait: window.matchMedia('(orientation: portrait)').matches,
        };
    }

    componentDidMount() {
        this.mediaQueryList = window.matchMedia('(orientation: portrait)');
        this.mediaQueryList.addListener(this.updateIsPortrait);
    }

    updateIsPortrait = (event) => {
        this.setState({ isPortrait: event.matches });
    }

    componentWillUnmount() {
        this.mediaQueryList.removeListener(this.updateIsPortrait);
    }

    render() {
        const { t } = this.props;

        return (
            <div className="section-separation" id={gymData.id}>
                <div className={`${gymData.id}-title`}>
                    <h1>{t(gymData.title)}</h1>
                </div>
                <Carousel>
                    {gymData.slides.map((slide, index) => (
                        <Carousel.Item key={index}>
                            <div className={`section-description ${gymData.id} ${slide.style}`}>
                                <div className={`img div-img ` + slide.img}>
                                </div>
                                {slide.title && <div className="description-overlay gym-section">
                                    <div className="header-container">
                                        <h3>{t(slide.title)}</h3>
                                        {slide.list && <DetailButton buttonText={gymData.details} detailObject={slide} isPortrait={this.state.isPortrait} />}
                                    </div>
                                    {((slide.description && !this.state.isPortrait) || !slide.list) && <p>{t(slide.description)}</p>}
                                </div>
                                }
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
                <Brands />
            </div >
        );
    }
};

export default withTranslation()(Gym);