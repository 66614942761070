import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ItemInfo from './ItemInfo';
import { withTranslation } from 'react-i18next';
import "../styles/ListInfo.css";

class ListInfo extends Component {
    render() {
        const { t, itemListInfo, minHeight, isPortrait } = this.props;

        return (
            <div className="section-separation list-info" id={itemListInfo.identifier} >
                <div>
                    {itemListInfo.title && <h1>{t(itemListInfo.title)}</h1>}
                </div>
                <div>
                    {(itemListInfo.description && isPortrait) && <p>{t(itemListInfo.description)}</p>}
                </div>
                <div>
                    {itemListInfo.list && <Row xs={1} sm={1} md={2} lg={3} xl={4} xxl={5} className="g-4 justify-content-md-center">
                        {itemListInfo.list.map((item, index) => (
                            <Col key={index} className={"listInfo-section " + (item.img ? 'with-img' : '')}>
                                <ItemInfo item={item} minHeight={minHeight} />
                            </Col>
                        ))}
                    </Row>}
                </div>
                <div className="listinfo-footer">
                    {itemListInfo.subtitle && <p>{t(itemListInfo.subtitle)}</p>}
                    {itemListInfo.attached && <p>*{t(itemListInfo.attached)}</p>}
                </div>
            </div>
        );
    }
}

export default withTranslation()(ListInfo);